@font-face {
  font-family: 'SF Pro Rounded';
  src: url('./assets/fonts/SF-Pro-Rounded-Bold.woff2') format('woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'SF Pro Rounded';
  src: url('./assets/fonts/SF-Pro-Rounded-Semibold.woff2') format('woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'SF Pro Rounded';
  src: url('./assets/fonts/SF-Pro-Rounded-Medium.woff2') format('woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'SF Pro Rounded';
  src: url('./assets/fonts/SF-Pro-Rounded-Regular.woff2') format('woff2');
  font-weight: normal;
}

* {
  padding: 0;
  margin: 0;
  font-size: 62.5%;
  font-family: 'SF Pro Rounded';
}

#root {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-image: url('../public/assets/images/background/main_bg.jpg');
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
}
